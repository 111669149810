$imgPath: '/assets/all-in-one-theme/images';
$fontPath: '/assets/all-in-one-theme/fonts';
$themeVariantImgPath: '/assets/all-in-one-theme/images/theme-variants/bringo-romania-theme';
$colors: ('primary': red, 'color_1': #4A397E, 'color_2': #FFB600, 'color_3': #2DA771, 'color_4': #6D6D6D, 'color_5': #F42626, 'color_6': #EAB818, 'color_7': #FAB72C, 'color_8': #F5F5F5, 'color_9': #E9B818, 'color_10': #362768, 'color_11': rgba(74, 57, 126, 0.85), 'color_12': rgba(74, 57, 126, 1), 'color_13': #FFB600, 'color_14': #FFB600, 'color_15': #362768, 'color_16': #273C51);
$header: ('logo_img': 'bringo-logo.svg', 'logo_img_mobile': 'bringo-logo.svg', 'logo_width': 146px, 'logo_height': 46px, 'logo_width_mobile': 120px, 'logo_height_mobile': 38px, 'nav_width': 125px, 'nav_height': 40px, 'nav_margin_top': 0, 'nav_bg_img': 'bringo-logo.svg', 'hamburger_position_top': 27px);
$units: ('hamburger_menu_icon_top': 10px, 'nav_center_column_mt': 0, 'logo_width': 154px);
$fonts: ('default': 'Manrope');body .pac-container {
  z-index: 6000!important;
  background: #FFFFFF;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 0 15px 15px;
  border: 0;
  padding: 15px 10px;
  min-width: 328px;
  margin-left: -59px;
  /*margin-top: -15px;*/
  border-top: 1px solid rgba(0, 0, 0, 0.1)!important;
}
body .pac-container .pac-item .pac-item-query {
  font-size: 16px;
  color: map-get($colors, 'color_1');
  font-weight: 700;
}
body .pac-container .pac-item .pac-icon.pac-icon-marker {
  background-image: url($themeVariantImgPath + '/homepage/pin-location-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 18px;
  display: inline-flex;
  margin-right: 10px;
  margin-top: 0;
}
body .pac-container .pac-item:hover {
  background-color: transparent;
}
body .pac-container .pac-item {
  padding: 5px;
  border: 0;
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: map-get($colors, 'color_1');
  text-decoration: none!important;
}
body .pac-container.pac-logo:after {
  display: none!important;
}
body.checkout-address .pac-container {
  min-width: 305px;
  margin-left: 0;
  margin-top: -3px;
}
.address-section .address-box .address {
  position: relative;
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  transition: 200ms ease;
}
.bringo-billing-address .tooltip-info {
  background: map-get($colors, 'color_1');
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: absolute;
  bottom: 110px;
  left: 25px;
  right: auto;
  margin: auto;
  padding: 18px;
  display: flex;
  opacity: 0;
  width: 310px;
  flex-direction: row;
  align-items: center;
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  transition: 200ms ease;
  z-index: -100;
}
.bringo-shipping-address .tooltip-info {
  background: map-get($colors, 'color_1');
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: absolute;
  top: 70px;
  left: 25px;
  right: auto;
  margin: auto;
  padding: 18px;
  display: flex;
  opacity: 0;
  width: 310px;
  flex-direction: row;
  align-items: center;
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  transition: 200ms ease;
  z-index: -100;
}
.location-store .tooltip-info {
  background: map-get($colors, 'color_1');
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: absolute;
  bottom: 55px;
  left: 150px;
  right: auto;
  margin: auto;
  padding: 18px;
  display: flex;
  opacity: 0;
  width: 300px;
  flex-direction: row;
  align-items: center;
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  transition: 200ms ease;
  z-index: -100;
}
.address-section .address-box .address .tooltip-info {
  background: map-get($colors, 'color_1');
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 18px;
  display: flex;
  opacity: 0;
  width: calc(100% - 30px);
  flex-direction: row;
  align-items: center;
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  transition: 200ms ease;
}
.bringo-billing-address .tooltip-info .info-icon,
.bringo-shipping-address .tooltip-info .info-icon,
.location-store .tooltip-info .info-icon,
.address-section .address-box .address .tooltip-info .info-icon {
  background-image: url($themeVariantImgPath + '/icons/info-white-icon.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-right: 18px;
}
.bringo-billing-address .tooltip-info .text,
.bringo-shipping-address .tooltip-info .text,
.location-store .tooltip-info .text,
.address-section .address-box .address .tooltip-info .text {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  width: calc(100% - 42px);
}
.bringo-billing-address .tooltip-info:before,
.bringo-shipping-address .tooltip-info:before,
.location-store .tooltip-info:before,
.address-section .address-box .address .tooltip-info:before {
  bottom: -10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.bringo-billing-address .tooltip-info:before,
.bringo-shipping-address .tooltip-info:before,
.location-store .tooltip-info:before,
.address-section .address-box .address .tooltip-info:before {
  border-color: transparent;
  border-top-color: map-get($colors, 'color_1');
  border-width: 5px;
  margin-left: -5px;
  right: 0;
  left: 0;
  margin: auto;
}


#modalAlertChangeAddressSubmit .modal-dialog.modal-lg {
  max-width: 1000px;
}
#modalAlertChangeAddressSubmit .modal-content {
  background: #F4F4F4;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border: 0;
}
#modalAlertChangeAddressSubmit button.close {
  position: absolute;
  z-index: 15;
  right: 30px;
  top: 30px;
  text-shadow: none!important;
  opacity: 1!important;
}
#modalAlertChangeAddressSubmit .modal-header {
  border: 0;
  padding: 0;
}
#modalAlertChangeAddressSubmit .modal-body p {
  font-family: map-get($fonts, 'default');
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: map-get($colors, 'color_1');
  margin-top: 0;
  margin-bottom: 0;
}
#modalAlertChangeAddressSubmit .modal-body {
  padding: 80px 40px 40px 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
#modalAlertChangeAddressSubmit .modal-footer {
  border: 0;
  padding: 0 0 30px 0;
  display: flex;
  justify-content: center;
}
#modalAlertChangeAddressSubmit .modal-footer a {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 10px 25px;
  border: 0;
  text-decoration: none!important;
  outline: none!important;
  margin: 0 10px;
  display: inline-flex;
}
#modalAlertChangeAddressSubmit .modal-footer .btn {
  background-color: map-get($colors, 'color_6');
}

@media (max-width: 767px){
  body .pac-container {
    min-width: calc(100% - 30px);
    margin-left: -56px;
  }
  body.modal-open .pac-container {
    min-width: calc(100% - 47px);
    margin-left: -59px;
  }
  .location-store .tooltip-info {
    bottom: auto;
    top: -90px;
    left: 0;
    right: 0;
    width: 290px;
  }
  body.stores-list .pac-container {
    min-width: calc(100% - 30px);
    margin-left: 0;
    left: 15px!important;
  }
  .bringo-billing-address .tooltip-info {
    bottom: 180px;
    left: 30px;
    width: calc(100% - 60px);
  }
  .bringo-shipping-address .tooltip-info {
    top: 130px;
    left: 30px;
    width: calc(100% - 60px);
  }
}
